$blue: #37b8d4;
$blue_font: #00529f;
$grey_font: #666666;
$grey_font_light: #7e7e7e;
$grey_back: #e3e3e3;
$green: #009d51;
$red: #f33840;
$orange: #f07b21;
$light_blue_font: #bae6f0;

$pt: 'PT Sans Narrow';
$ar: 'Arial';

@mixin small_desktop {
  @media all and (max-width: 1279px) { @content; }
}
@mixin tablet {
  @media all and (max-width: 1024px) { @content; }
}
@mixin big_phone {
  @media all and (max-width: 767px) { @content; }
}
@mixin phone {
  @media all and (max-width: 479px) { @content; }
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder { color: $color; }
  &::-moz-placeholder { color: $color; }
  &:-moz-placeholder { color: $color; }
  &:-ms-input-placeholder { color: $color; }
}

body {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.hidden { display: none !important; }

.wrapper {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.g-text-center {
  text-align: center;
}

.mobile_hidden {
  @include big_phone {
    display: none;
  }
}

.desktop_hidden {
  display: none;

  @include big_phone {
    display: block;
  }
}

#page404 {
  margin: 0 auto;
  font-size: 300px;
  color: $orange;

  @include big_phone {
    font-size: 150px;
  }
}