$label_width_desk: 70px;
$label_left_desk: -18px;

$label_width_mob: 42px;
$label_left_mob: -12px;

.popup {
  display: none;
  z-index: 2;
  .background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
  }
  .modal {
    display: flex;
    line-height: 1;
    position: fixed;
    width: 613px;
    height: 507px;
    background: #edeef1;
    background-image: url("/static/img/popup.jpg");
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 3px;

    flex-direction: column;
    align-items: center;

    font-family: "PT Sans Narrow";
    font-weight: 700;
    font-size: 25px;

    .ts-1 {
      min-height: 25px;
      margin: 86px 0 0 0;
      color: $grey_font;
    }

    .ts-2 {
      font-size: 50px;
      color: $orange;
      margin: 5px 0 0 0;
    }

    .ts-3 {
      font-family: "Arial";
      font-size: 15px;
      text-align: center;
      max-width: 360px;
      color: $grey_font;
      letter-spacing: -0.5px;
      line-height: 20px;
      margin: 25px 0 0 0;
      font-weight: normal;

      a {
        color: #1784ff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .ts-4 {
      font-family: "Arial";
      font-size: 18px;
      color: #52575b;
      margin: 20px 0 0 0;
      text-align: center;
      line-height: 24px;
      max-width: 300px;
    }

    .ts-5 {
      color: $orange;
      margin: 10px 0 0 0;
    }

    .cta {
      display: inline-block;
      padding: 15px 20px;
      font-size: 20px;
      background: $orange;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      -moz-border-radius: 3px;
      border-radius: 3px;
      margin: 24px auto 0;
      cursor: pointer;
      &:hover {
        background: #f28939;
      }
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      @extend .icon-close;
    }
  }
}

.popup-label {
  position: fixed;
  left: $label_left_desk;
  bottom: 50px;
  width: $label_width_desk;
  height: 92px;

  background: $orange;
  border-radius: 0 20px 20px 0;
  line-height: 12.6px;
  cursor: pointer;
  transition: left 1s;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.show {
    left: 0;
  }

  .icon-present {
    margin: 16px 14px 0 0;
  }

  .ts-1 {
    font-family: "Arial";
    max-width: 40px;
    color: white;
    font-size: 11.7px;
    font-weight: bold;
    margin: 5px 7px 0 0;
    text-align: center;
  }
}

@media (max-width: 620px) {
  .popup {
    .modal {
      width: 291px;
      height: 403px;
      background-size: 100%;

      .ts-1 {
        margin: 75px 0 0 0;
        font-size: 18.3px;
      }

      .ts-2 {
        margin: 10px 0 0 0;
        font-size: 30px;
      }

      .ts-3 {
        margin: 20px 0 0 0;
        font-size: 11px;
        line-height: 14.61px;
        max-width: 260px;
      }

      .ts-4 {
        margin: 18px 0 0 0;
        font-size: 13.15px;
        line-height: 17.53px;
        max-width: 165px;
      }

      .ts-5 {
        margin: 8px 0 0 0;
        font-size: 18.3px;
      }
    }
  }

  .popup-label {
    height: 91px;
    left: $label_left_mob;
    width: $label_width_mob;
    border-radius: 0 16px 16px 0;
    .icon-present {
      display: none;
    }
    .ts-1 {
      transform: rotate(270deg) translateX(-35px) translateY(30px);
      min-width: 80px;
    }
    &.show {
      left: 0;
    }
  }
}