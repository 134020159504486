/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-arrow {
  background-image: url(/static/img/sprite.png);
  background-position: -157px -54px;
  width: 20px;
  height: 20px;
}
.icon-basket {
  background-image: url(/static/img/sprite.png);
  background-position: 0px -79px;
  width: 33px;
  height: 33px;
}
.icon-clock {
  background-image: url(/static/img/sprite.png);
  background-position: -157px -125px;
  width: 13px;
  height: 13px;
}
.icon-close {
  background-image: url(/static/img/sprite.png);
  background-position: -157px 0px;
  width: 27px;
  height: 27px;
}
.icon-geolocation {
  background-image: url(/static/img/sprite.png);
  background-position: 0px -142px;
  width: 11px;
  height: 15px;
}
.icon-in {
  background-image: url(/static/img/sprite.png);
  background-position: -63px -79px;
  width: 30px;
  height: 30px;
}
.icon-in_hover {
  background-image: url(/static/img/sprite.png);
  background-position: -93px -79px;
  width: 30px;
  height: 30px;
}
.icon-in_law {
  background-image: url(/static/img/sprite.png);
  background-position: -123px -79px;
  width: 30px;
  height: 30px;
}
.icon-ok {
  background-image: url(/static/img/sprite.png);
  background-position: -33px -79px;
  width: 30px;
  height: 30px;
}
.icon-ok_button {
  background-image: url(/static/img/sprite.png);
  background-position: 0px 0px;
  width: 157px;
  height: 40px;
}
.icon-ok_hover {
  background-image: url(/static/img/sprite.png);
  background-position: -30px -112px;
  width: 30px;
  height: 30px;
}
.icon-ok_law {
  background-image: url(/static/img/sprite.png);
  background-position: -90px -112px;
  width: 30px;
  height: 30px;
}
.icon-phone {
  background-image: url(/static/img/sprite.png);
  background-position: -177px -54px;
  width: 7px;
  height: 20px;
}
.icon-present {
  background-image: url(/static/img/sprite.png);
  background-position: -157px -27px;
  width: 27px;
  height: 27px;
}
.icon-viber_lead {
  background-image: url(/static/img/sprite.png);
  background-position: -157px -93px;
  width: 18px;
  height: 20px;
}
.icon-vk {
  background-image: url(/static/img/sprite.png);
  background-position: -120px -112px;
  width: 30px;
  height: 30px;
}
.icon-vk_button {
  background-image: url(/static/img/sprite.png);
  background-position: 0px -40px;
  width: 155px;
  height: 39px;
}
.icon-vk_hover {
  background-image: url(/static/img/sprite.png);
  background-position: -60px -112px;
  width: 30px;
  height: 30px;
}
.icon-vk_law {
  background-image: url(/static/img/sprite.png);
  background-position: 0px -112px;
  width: 30px;
  height: 30px;
}
.icon-vk_lead {
  background-image: url(/static/img/sprite.png);
  background-position: -157px -113px;
  width: 19px;
  height: 12px;
}
.icon-whatsapp_lead {
  background-image: url(/static/img/sprite.png);
  background-position: -157px -74px;
  width: 19px;
  height: 19px;
}
