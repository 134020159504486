.header {
  position: relative;
  background-color: $blue;
  height: 600px;

  @include big_phone {
    height: 290px;
  }

  .wrapper {
    position: relative;
    padding: 0 40px;
    height: 100%;
    overflow: hidden;

    @include tablet {
      padding: 0 10px;
    }

    @include big_phone {
      padding: 0;
    }
  }

  .header_background {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    object-fit: contain;

    @include big_phone {
      height: 75%;
      left: -45px;
      top: auto;
      bottom: 0;
    }
  }

  .header_top_panel {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include phone {
      background: #fff;
    }
  }

  .header_logo {
    padding: 20px 25px;
    background-color: rgba(255, 255, 255, 0.8);

    img {
      display: block;
    }

    @include big_phone {
      padding: 10px 15px;
      background: transparent;

      img {
        width: 83px;
        height: auto;
      }
    }
  }

  .header_tel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .tel {
      font-family: $pt;
      font-weight: 700;
      font-size: 30px;
      color: #fff;
      outline: none;
      text-decoration: none;
    }

    .work_time {
      font-family: $pt;
      font-size: 13px;
      color: #000;
    }

    @include tablet {
      margin-right: 45px;
    }

    @include big_phone {
      margin-right: 10px;

      .tel {
        font-size: 16px;
        color: $blue_font;
      }

      .work_time {
        font-size: 9px;
      }
    }
  }

  .header_social {
    display: flex;

    @include tablet {
      display: none;
    }

    .header_social_link {
      margin-left: 7px;

      @each $i in ('vk', 'ok', 'in') {
        &.#{$i} {
          @extend .icon-#{$i};

          &:hover {
            @extend .icon-#{$i}_hover;
          }
        }
      }
    }
  }

  .header_banner {
    position: absolute;
    width: 300px;
    top: 150px;
    right: 190px;

    p {
      margin: 0;
    }

    .banner_title {
      display: block;
      font-family: $pt;
      font-weight: 700;
      font-size: 70px;
      color: #fff;
      text-transform: uppercase;
    }

    .banner_subtitle {
      display: block;
      font-family: $pt;
      font-weight: 700;
      font-size: 50px;
      color: #fff;
      text-transform: uppercase;
    }

    .banner_text {
      margin: 0;
      font-family: $ar;
      font-size: 14px;
      color: $light_blue_font;
      max-width: 135px;
    }

    @include tablet {
      right: 60px;
    }

    @include big_phone {
      top: 75px;
      right: 10px;
      width: 150px;

      .banner_title {
        font-size: 35px;
      }

      .banner_subtitle {
        font-size: 25px;
      }

      .banner_text {
        display: none;
      }

      .main_link {
        font-size: 16px;
      }
    }
  }

  .header_tabs {
    width: calc(100% - 80px);
    position: absolute;
    left: 40px;
    bottom: 0;
    display: flex;
    align-items: flex-end;

    @include tablet {
      width: 100%;
      left: 0;
    }

    .header_tab {
      width: 50%;
      height: 75px;
      background: rgba(255, 255, 255, 0.7);
      font-family: $pt;
      font-size: 20px;
      color: $grey_font_light;
      text-decoration: none;
      line-height: 1.1;
      padding: 18px 0 0 20px;

      &.active {
        height: 100px;
        background: rgba(240, 123, 33, 0.8);
        color: #fff;
      }
    }
  }
}

.main {
  .breadcrumbs {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    color: #52575b;
    font-size: 15px;
    font-family: $ar;
    max-width: 1240px;
    margin: 15px auto;
    li {
      color: #a8b2ba;
      &:after {
        content: "/";
        color: #a8b2ba;
        margin: 0 4px 0 0;
      }
      &:last-child {
        &:after {
          content: ""
        }
        a {
          color: #52575b;
          span {
            text-decoration: none;
          }
        }
      }
      a {
        color: #a8b2ba;
        text-decoration: none;
        span {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    @include tablet {
      padding-left: 10px;
    }
  }
}

.main_content {
  .wrapper {
    padding: 20px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include tablet {
      padding: 50px 10px;
    }

    @include big_phone {
      padding: 35px 10px;
    }

    > img {
      display: block;
      width: 50%;
      height: 100%;

      @include big_phone {
        display: none;
      }
    }

    > h2 {
      font-family: $pt;
      font-size: 30px;
      font-weight: bold;
      color: $grey_font;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    font-family: $pt;
    text-transform: uppercase;
    font-size: 30px;
    margin: 0 0 20px 0;
    color: $grey_font;

    @include tablet {
      margin-bottom: 25px;
    }

    @include big_phone {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .main_text_block {
    width: 50%;
    padding: 18px 0 0 40px;
    .price {
      .old {
        font-size: 22px;
        color: #cecece;
        text-decoration: line-through;
        margin-right: 10px;
      }
      .current {
        font-size: 30px;
        color: #37b8d4;
      }
    }
    .leads_catcher {
      max-width: 410px;
      background: #fef7f0;
      border-radius: 2px;
      margin: 38px auto 0;
      .title {
        display: block;
        font-family: $pt;
        font-size: 20px;
        font-weight: bold;
        color: $orange;
        text-transform: uppercase;
        padding: 17px;
        text-align: center;
      }
      .manager {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        figure {
          position: relative;
          img {
            width: 60px;
            height: 60px;
          }
          &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            display: block;
            width: 14px;
            height: 14px;
            background: #8ed09a;
            border: 2px solid white;
            border-radius: 50%;
            animation-name: blink;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            @keyframes blink {
              0% {
                background: #8ed09a;
              }
              50% {
                background: #49b35c;
              }
            }
          }
        }
        > span {
          font-family: $pt;
          font-size: 19px;
          color: $grey_font;
          margin: 0 0 0 14px;
          > span {
            font-family: $ar;
            font-size: 14px;
            color: $grey_font_light;
            display: block;
          }
        }
      }
      .contacts {
        padding: 18px 0 10px 0;
        background: #fdefe3;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        > span {
          font-family: $ar;
          font-size: 14px;
          color: #acacac;
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }
        a {
          font-family: $ar;
          font-size: 12px;
          color: white;
          padding: 5px 10px 5px 10px;
          margin: 0 5px 10px 5px;
          width: 104px;
          height: 30px;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          position: relative;
          overflow: hidden;
          &:after {
            content: '';
            display: block;
            height: 60px;
            box-shadow: 0 0 60px 20px white;
            opacity: 0.4;
            transform: rotate(20deg);
            position: absolute;
            top: -15px;
            left: -55px;
            animation-name: twinkle;
            animation-duration: 12s;
            animation-iteration-count: infinite;
            @keyframes twinkle {
              80% {
                left: -55px
              }
              100% {
                left: 155px
              }
            }
          }
          &:before {
            content: '';
            margin-right: 5px;
          }
          &.viber {
            background: #7d3daf;
            &:before {
              @extend .icon-viber_lead;
            }
          }
          &.whatsapp {
            background: #1bd741;
            &:before {
              @extend .icon-whatsapp_lead;
            }
          }
          &.vk {
            background: #4d76a1;
            &:before {
              @extend .icon-vk_lead;
            }
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      @include tablet {
        margin: 30px auto 0 auto;
      }
      @media all and (max-width: 390px) {
        .contacts {
          flex-direction: column;
          align-items: center;
          a {
            margin-bottom: 10px;
            width: 50%;
            &:after {
              all: unset;
            }
          }
          span:last-child {
            display: none;
          }
        }
      }
    }

    @include tablet {
      padding-top: 0;
    }
    @include big_phone {
      padding-left: 0;
      width: 100%;
    }
  }

  h2 {
    font-family: $pt;
    font-weight: bold;
    font-size: 19px;
    color: $grey_font;
    margin-bottom: 10px;
    @include big_phone {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  p, ul {
    font-family: $ar;
    font-size: 14px;
    color: $grey_font_light;
    margin-bottom: 20px;
    @include big_phone {
      max-width: 100%;
    }
  }

  .products_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    .product_cell {
      text-decoration: none;
      width: calc((100% - (30px * 5)) / 6);
      margin-right: 30px;
      margin-bottom: 20px;
      position: relative;
      .price {
        font-family: $pt;
        font-size: 15px;
        color: $green;
        text-align: center;
        display: flex;
        justify-content: center;
        @include phone {
          flex-direction: column;
        }
        .old {
          color: $red;
          text-decoration: line-through;
          margin-right: 10px;
          @include phone {
            margin-right: 0;
          }
        }
      }
      &.can_order {
        &:after {
          @extend .icon-basket;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      &:nth-child(6n) {
        margin-right: 0;
      }
      @include big_phone {
        width: calc((100% - (30px * 3)) / 4);
        margin-bottom: 15px;

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-child(6n) {
          margin-right: 30px;
        }
      }
      @include phone {
        width: calc((100% - (20px * 2)) / 3);
        margin-right: 20px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(4n) {
          margin-right: 20px;
        }

        &:nth-child(6n) {
          margin-right: 0;
        }
      }
    }

    .product_img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    h3 {
      margin: 0;
      font-family: $pt;
      font-weight: 400;
      font-size: 15px;
      color: $grey_font_light;
      text-align: center;

      @include big_phone {
        font-size: 13px;
      }
    }
  }
  .other_product_information {
    width: 100%;
    margin-top: 20px;
    p {
      max-width: none;
      display: flex;
      flex-direction: column;
    }
    table tbody {
      tr th {
        border: 1px solid $grey_back;
        font-family: $ar;
        font-size: 14px;
        color: $grey_font;
        padding: 3px 7px 3px 7px;
        text-align: left;
      }
      tr td {
        border: 1px solid $grey_back;
        font-family: $ar;
        font-size: 14px;
        color: $grey_font;
        padding: 3px 7px 3px 7px;
      }

    }
    h2 {
      margin-bottom: 20px;
    }
    img {
      max-width: 80%;
      align-self: center;
    }
    ul li {
      list-style-type: none;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 0;
        border: 2px solid $orange;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        left: -15px;
      }
    }
    a {
      font-family: inherit;
      color: #1784ff;
      text-decoration: none;
    }
  }
}

.main_link,
.main_content p a.main_link {
  display: inline-block;
  font-family: $pt;
  padding: 15px 20px;
  font-size: 20px;
  background: $orange;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 15px auto 0;

  &:hover {
    background: lighten($orange, 5%);
  }
}

.photosalons_content {
  background: $grey_back;
  padding: 77px 0 65px;

  @include tablet {
    padding: 35px 0;

    .wrapper {
      padding: 0 10px;
    }
  }

  .photosalons_title {
    width: 100%;
    text-align: center;
    font-family: $pt;
    text-transform: uppercase;
    font-size: 30px;
    margin: 0 0 45px;
    color: $grey_font;

    @include big_phone {
      font-size: 18px;
    }
  }

  .photosalons_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .photosalon_cell {
    display: flex;
    background: #fff;
    width: calc((100% - 50px) / 3);
    margin-right: 25px;
    margin-bottom: 25px;
    min-height: 161px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @include tablet {
      width: calc((100% - 20px) / 2);
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(3n) {
        margin-right: 20px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include big_phone {
      width: 100%;
      margin-right: 0;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .img_container {
    overflow: hidden;
    flex-basis: 150px;
    flex-shrink: 0;
    background: url('/static/img/photosalon_img.png') no-repeat center center;
    img {
      display: block;
      min-width: 100%;
      min-height: 100%;
    }
    @include phone {
      flex-basis: 100px;
    }
  }

  .info_container {
    padding: 12px 12px 12px 20px;
    display: flex;
    flex-direction: column;
    @include big_phone {
      padding: 10px 0 10px 13px;
    }
  }

  .photosalon_title {
    font-family: $pt;
    font-size: 19px;
    font-weight: 400;
    color: $grey_font_light;
    margin: 0 0 10px;

    a {
      font-family: inherit;
      color: $blue;
      text-decoration: none;
    }

    @include big_phone {
      font-size: 15px;
    }
  }

  .address {
    position: relative;
    padding: 0 0 0 20px;
    margin-bottom: 6px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      @extend .icon-geolocation;
    }
    p {
      margin: 0 0 5px;
      font-family: $ar;
      font-size: 12px;
      line-height: 1.6;
      color: $grey_font_light;
    }
    @include big_phone {
      margin-bottom: 7px;
    }
  }
  .work_time {
    position: relative;
    padding: 0 0 0 20px;
    margin-bottom: 6px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      @extend .icon-clock;
    }
    p {
      margin: 0 0 5px;
      font-family: $ar;
      font-size: 12px;
      line-height: 1.6;
      color: $grey_font_light;
    }
  }
  .salon_tel {
    position: relative;
    padding: 0 0 0 20px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 2px;
      @extend .icon-phone;
    }
    p {
      margin: 0 0 5px;
      font-family: $ar;
      font-size: 12px;
      line-height: 1.6;
      color: $grey_font_light;
    }
  }
}

.footer {
  background: #f0f4f6;
  padding: 30px 0;

  @include big_phone {
    .wrapper {
      padding: 0 10px;
    }
  }

  .footer_content {
    display: flex;
    align-items: center;
  }

  .footer_cell {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    border-right: 1px solid $grey_font;

    &:last-of-type {
      border-right: none;
    }

    @include big_phone {
      width: auto;
      border: none;
      margin-right: 10px;
    }
  }

  .social_link {
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }

    @each $i in ('vk', 'ok') {
      &.#{$i}_button.mobile_hidden {
        @extend .icon-#{$i}_button;
      }
    }

    @each $i in ('vk', 'ok') {
      &.#{$i}_button.desktop_hidden {
        @extend .icon-#{$i}_hover;
      }
    }
  }

  .tel {
    font-family: $pt;
    color: $grey_font;
    font-size: 25px;

    a {
      color: $grey_font;
      text-decoration: none;
    }

    .work_time {
      font-size: 16px;
      padding-left: 15px;
    }

    @include tablet {
      .work_time {
        display: block;
        text-align: right;
      }
    }

    @include big_phone {
      display: none;
    }
  }

  .scroll_arrow {
    @extend .icon-arrow;
    transform: scale(1.6);
    margin-left: auto;
    margin-right: 10px;
  }
}

.law {
  .header {
    background: $grey_back;

    .header_social {
      .header_social_link {
        @each $i in ('vk', 'ok', 'in') {
          &.#{$i} {
            @extend .icon-#{$i}_law;

            &:hover {
              @extend .icon-#{$i}_hover;
            }
          }
        }
      }
    }

    .header_tel {
      .tel {
        color: $blue_font;
      }
    }

    .header_tabs {
      .header_tab {
        &.active {
          background: rgba(0, 82, 159, 0.8);
        }
      }
    }

    .header_banner {
      position: absolute;
      width: 300px;
      top: 210px;
      left: 190px;

      .banner_text {
        color: $blue_font;
      }

      .banner_title {
        color: $orange;
      }

      .banner_subtitle {
        color: $orange;
      }

      @include tablet {
        right: auto;
        left: 60px;
      }

      @include big_phone {
        left: 10px;
        top: 95px;
      }
    }

    .header_background {
      left: auto;
      right: -65px;

      @include big_phone {
        right: -85px;
      }
    }
  }
}

.text_page {
  .header {
    height: auto;

    @include big_phone {
      height: auto;
    }

    .header_top_panel {
      @include phone {
        background: $blue;
      }
    }

    .header_logo {
      @include big_phone {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .main_content {
    .wrapper {
      > img {
        @include big_phone {
          display: block;
          margin: auto;
        }
      }
    }
  }
}